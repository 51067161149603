import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { PostCardHalf, Layout, Authors, ImageHeader, TagHelperArray } from '../components/common'
import { MetaData } from '../components/common/meta'
import config from '../utils/siteConfig'
import { device } from "../utils/device.js"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import {HeaderXXL, BodyText, H5, H3, HeaderCursive, HeaderCursiveSmall, Koenig} from "../utils/typography"

/**
* Author page (/author/:slug)
*
* Loads all posts for the requested author incl. pagination.
*
*/
const Author = ({ data, location, pageContext }) => {
    const author = data.ghostAuthor
    const twitterUrl = author.twitter ? `https://twitter.com/${author.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = author.facebook ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}` : null

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="profile"
            />
            <Layout>
                <ImageHeader title={author.name}  image={author.localCoverImage}/>
                <CenteredContent>
                  <Content>
                    <MyImage fluid={author.localProfileImage.childImageSharp.fluid} alt={author.name}/>
                    <Text>
                      {author.bio}
                    </Text>
                  </Content>
              </CenteredContent>
            </Layout>
        </>
    )
}

const MyImage = styled(Image)`
    float: none;
    margin: 0 0 2em 0;



  @media ${device.tablet}{
    width: 400px;
    float: left;
    margin: 0 2em 2em 0;
  }
`
const Text = styled("div")`
  ${BodyText}
`

const Content = styled("div")`
  padding: 2rem 0;
`

const IndexContainer = styled("div")`
  margin: 10rem 0;
`

const IndexHeader = styled("div")`
  margin: -0.25em 0.2em;
  padding: 0;
  z-index: 10;
  position: relative;
  ${HeaderXXL}

  >h2{
    margin: 0;
    padding: 0;
    display: block;
    color: ${config.textColorDark};
    }
`

const CenteredContent = styled("div")`
  margin-left: auto;
  margin-right: auto;
  max-width: ${config.contentMaxWidth};
  width: 95%;
  min-height: 50vh;

  @media ${device.desktop} {
    max-width: calc(${config.contentMaxWidth} * 1.2);
  }
`

Author.propTypes = {
    data: PropTypes.shape({
        ghostAuthor: PropTypes.shape({
            name: PropTypes.string.isRequired,
            cover_image: PropTypes.string,
            profile_image: PropTypes.string,
            website: PropTypes.string,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Author

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!) {
        ghostAuthor(slug: { eq: $slug }) {
            ...GhostAuthorFields
            localCoverImage {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          localProfileImage {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
`
